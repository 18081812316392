<template>
  <div class="cars">
    <div class="title">Our Cars</div>
    <CarFilter @filter-change="filterChange"></CarFilter>
    <div class="car-info">
      <template v-for="(c, i) in classes">
        <div :key="i" class="car-class" v-if="filter.class === '' || filter.class === c">{{c}}</div>
        <template v-for="(car, ind) in cars">
          <CarsPageCard v-if="c === car.class && carVisible(car)" :key="i + '-' + ind" :car="car"></CarsPageCard>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import CarFilter from "../components/CarFilter";
import CarsPageCard from "../components/CarsPageCard";

export default {
  name: "Cars",
  components: {
    CarFilter, CarsPageCard
  },
  data() {
    return {
      classes: ['Hatchback','Passenger Van','Sedan'],
      cars: [],
      filter: {
        class: '',
        brand: 0,
        transmission: 0,
        fuel: 0,
        max_passengers: 0
      }
    }
  },
  created() {
    this.$store.dispatch('isReady').then(() => {
      this.cars = this.$store.getters.siteData.cars
      // for (let i = 0; i < this.cars.length; i++) {
      //   this.cars[i].visible = true
      // }
    })
  },
  methods: {
    carVisible(car) {
      if (!car) return false
      if (this.filterDisabled()) return true
      return (this.filter.class === '' || this.filter.class === car.class) && (this.filter.brand === 0 || this.filter.brand === car.brand) &&
          (this.filter.transmission === 0 || this.filter.transmission === car.transmission) && (this.filter.fuel === 0 || this.filter.fuel === car.fuel) &&
          (this.filter.max_passengers === 0 || this.filter.max_passengers >= car.max_passengers)
    },
    filterDisabled() {
      return this.filter.class === '' && this.filter.brand === 0 && this.filter.transmission === 0 &&
          this.filter.fuel === 0 && this.filter.max_passengers === 0
    },
    filterChange(e) {
      // console.log(JSON.stringify(e))
      if (e.default === true) {
        for (let car of this.cars) {
          if (car) {
            switch (e.key) {
              case 'class':
                this.filter[e.key] = ''
                break;
              default:
                this.filter[e.key] = 0
            }
          }
        }
      } else {
        this.filter[e.key] = e.value
      }
    }
  }
}
</script>

<style scoped>
  .cars {
    width: 100%;
    padding-top: 130px;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
  }
  .title {
    background-color: rgba(0, 0, 0, 0.05);
    height: 7.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.9rem;
    font-stretch: normal;
  }
  .car-info {
    padding: 20px 14%;
    width: 72%;
    display: flex;
    flex-direction: column;
  }
  .car-info .car-class {
    width: calc(100% - 10px);
    border-radius: 4px;
    background-color: rgb(255, 206, 7);
    box-shadow: rgba(0, 0, 0, 0.33) 1px 1px 3px 0;
    color: rgb(255, 255, 255);
    text-align: left;
    padding: 10px 0 10px 10px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    .car-info {
      width: 90%;
      padding: 20px 5%;
    }
  }
</style>