<template>
  <div class="select" :class="{'opened': optionsOpened}" :style="{'z-index': 400 + index}">
    <div class="screen">
      <div @click.self="selectClick">{{chosenValue}}</div>
      <div @click="selectClick">
        <div class="arrow" :class="{'up': optionsOpened}"></div>
      </div>
      <transition name="opt"
      @enter="onEnter"
      @leave="onLeave">
        <div class="options" v-if="optionsOpened" @click.prevent>
          <div class="search">
            <input type="text" v-model="searchValue" @input="onSearch" :ref="'s-' + defaultOption" :id="'s-' + defaultOption" placeholder="Search...">
          </div>
          <div class="option" v-if="coincidenceNotFound()">No results found</div>
          <div class="option first" @click="optionClick(undefined, true)">{{defaultOption}}</div>
          <template v-for="(o, i) in optionArray">
            <div class="option" :key="i" v-if="o.visible" @click="optionClick(o)">{{o.value}}</div>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarSelect",
  props: {
    defaultOption: {type: String, required: true},
    setToDefault: {type: Boolean, default: false},
    options: {type: Array, default: () => []},
    index: {type: Number, default: 0}
  },
  data() {
    return {
      optionsOpened: false,
      chosenValue: '',
      activeOptionKey: '',
      searchValue: '',
      optionArray: []
    }
  },
  watch: {
    options: function () {
      console.log(this.defaultOption, JSON.stringify(this.options))
      this.createOptions()
    },
    setToDefault: function () {
      this.chosenValue = this.defaultOption
      this.$emit('on-click', {default: true})
    }
  },
  created() {
    this.createOptions()
  },
  methods: {
    createOptions() {
      this.chosenValue = this.defaultOption
      // console.log('CREATE OPTIONS ', typeof this.options[0])
      if (this.options && this.options.length) {
        if (typeof this.options[0] === 'object') {
          for (let option of this.options) {
            option.visible = true;
            this.optionArray.push(option)
          }
        } else {
          for (let option of this.options) {
            const obj = {};
            obj.key = option;
            obj.value = option;
            obj.visible = true;
            this.optionArray.push(obj);
          }
        }
      }
    },
    selectClick() {
      this.optionsOpened = !this.optionsOpened;
      this.$nextTick().then(() => {
        // console.log(this.optionsOpened, this.$refs)
        if (this.optionsOpened) this.$refs[`s-${this.defaultOption}`].focus()
      })
    },
    onEnter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
        element.style.bottom = '-' + height;
      });
    },
    onLeave(element) {
      element.style.height = getComputedStyle(element).height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
        element.style.bottom = 0;
      });
    },
    onSearch() {
      console.log(JSON.stringify(this.optionArray));
      if (this.searchValue === '') {
        this.setOptionsVisible();
        return;
      }
      for (let o of this.optionArray) {
        // console.log(!o.value.toLowerCase().indexOf(this.searchValue.toLowerCase()) < 0)
        o.visible = o.value.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0;
      }
      console.log(JSON.stringify(this.optionArray));
    },
    coincidenceNotFound() {
      if (!this.optionArray.length) return false;
      for (let i = 0; i < this.optionArray.length; i++) {
        // console.log(this.optionArray[i].visible)
        if (this.optionArray[i].visible === true) return false;
        if (i === this.optionArray.length - 1) return true;
      }
    },
    setOptionsVisible() {
      for (let o of this.optionArray) {
        o.visible = true;
      }
    },
    optionClick(option, isDefault) {
      if (isDefault === true) {
        // console.log('Default')
        this.chosenValue = this.defaultOption
        this.$emit('on-click', {default: true})
      } else {
        this.chosenValue = option.value
        this.$emit('on-click', {default: false, value: option.key})
      }
      this.optionsOpened = false
    }
  }
}
</script>

<style scoped>
  .select {
    position: relative;
    width: clamp(190px, 200px, 210px);
    height: 2.2rem;
    /*background-color: #1E90FF;*/
    align-self: center;
    border: none;
    border-radius: 6px;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.8);
    cursor: pointer;
    z-index: 400;
  }
  .select.opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .screen {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .screen > div:not(.options) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .screen > div:first-child {
    flex: 11;
    text-align: left;
    padding-left: 10px;
  }
  .screen > div:not(:first-child):not(.options) {
    flex: 1;
  }
  .screen .arrow {
    width: 5px;
    height: 5px;
    background-color: transparent;
    border-bottom: 2px solid rgba(0,0,0,0.8);
    border-right: 2px solid rgba(0,0,0,0.8);
    margin: auto;
    transform: rotate(45deg);
  }
  .screen .arrow.up {
    transform: rotate(225deg);
  }
  .options {
    position: absolute;
    bottom: 0;
    height: 0;
    /*max-height: 5rem;*/
    background-color: #000000;
    color: #ffffff;
    animation-fill-mode: forwards;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    /*padding: 0 1%;*/
    transition: all .2s ease;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    z-index: 500;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.6);
  }
  .options > *:not(.search) {
    margin-top: 2px;
    min-height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .options > .search {
    width: 100%;
    background-color: #ffffff;
    align-items: center;
    min-height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
  }
  input[type=text] {
    flex: 1;
    padding: 0;
    margin: auto;
    max-height: 1.6rem;
    max-width: 95%;
    border-radius: 4px;
  }
  .option {
    width: calc(100% - 20px);
    padding: 0 10px;
    text-align: left;
    font-family: "Raleway", sans-serif;
  }
  .option:not(.first) {
    border-top: 1px solid rgba(255,255,255,0.5);
  }
  .option:hover {
    background-color: #FF8C00;
  }
  /*.opt-enter-active {*/
  /*  animation: open_select .5s ease;*/
  /*}*/
  /*.opt-leave-active {*/
  /*  animation: open_select .5s ease reverse;*/
  /*}*/
  @keyframes open_select {
    0 % {
      height: 0;
      bottom: 0;
      opacity: 0;
    }
    100% {
      height: 5rem;
      bottom: -5rem;
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px){
    .select {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px){
    .select {
      width: 49%;
    }
  }
</style>