<template>
  <div class="car-info-card">
    <img :src="getImageSrc(car.image, 'cars')" alt="">
    <div class="car-data">
      <span class="name">{{car.name}}</span>
      <div class="hr"></div>
      <div class="data-div">
        <div class="data-div-info"><span class="icon-car"></span><span>Class: {{car.class}}</span></div>
        <div class="data-div-info"><span class="icon-cogs"></span><span>Gearbox: {{gearbox[car.transmission]}}</span></div>
        <div class="data-div-info"><span class="icon-users"></span><span>Max passengers: {{car.passengers}}</span></div>
        <div class="data-div-info" :style="{'margin-top': !isMobile ? '10px' : 0}"><span class="icon-tachometer"></span><span>Fuel: {{fuel[car.fuel]}}</span></div>
      </div>
      <span style="margin-top: 10px;">FEATURES</span>
      <div class="hr"></div>
      <div class="data-div">
        <div v-for="(o, i) in car.options" :key="i" :style="{'margin-top': i <= 2 ? '5px' : '10px'}">
          <span class="icon-check">{{o}}&nbsp;</span>
        </div>
      </div>
    </div>
    <div class="car-prices">
      <div><span>DAY FROM</span><span><b>&#x20ac;</b>&nbsp;{{car.fixed_price[siteId]}}</span></div>
      <div>
        <div><span>Deposit</span><span>{{car.deposit[siteId]}}&nbsp;EUR</span></div>
        <div><span>Mileage</span><span>Unlimited</span></div>
        <button @click="$router.push(`/car?i=${car.id}`)">Rent</button>
      </div>
    </div>
  </div>
</template>

<script>
import {isMobile} from 'mobile-device-detect'

import http from "../services/http";

export default {
  name: "CarsPageCard",
  props: {
    car: {type: Object, required: true}
  },
  watch: {
    // car: function () {
    //   console.log('CAR WATCH: ', JSON.stringify(this.car))
    // }
  },
  created() {
    // console.log(this.$route)
    // console.log('CREATED: ', JSON.stringify(this.car), isMobile)
    this.$store.dispatch('isReady').then(() => {
      this.siteId = this.$store.getters.siteId
    })
  },
  data() {
    return {
      gearbox: {1: 'Automatic', 2: 'Manual'},
      fuel: {1: 'Hybrid', 2: 'Unleaded 95', 3: 'Diesel'},
      siteId: 0,
      isMobile: isMobile
    }
  },
  methods: {
    getImageSrc(image, key) {
      return http.getImageSrc(image, key)
    }
  }
}
</script>

<style scoped>
.car-info-card {
  min-height: 1rem;
  /*border-radius: 5px;*/
  box-shadow: 1px 1px 4px rgba(0,0,0,0.3);
  /*background-color: #262262;*/
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 25px 10px;
}
.car-info-card > * {
  /*flex: 1;*/
  text-align: left;
}
.car-info-card > img {
  height: 180px;
  width: 250px;
  max-width: 250px;
}
.car-data, .car-prices {
  display: flex;
  flex-direction: column;
  /*flex: 1;*/
}
.car-data > *, .car-prices > * {
  width: 100%;
}
.car-prices {
  justify-content: space-between;
}
.car-data > span {
  font-size: 1.2rem;
}
.car-data .name {
  color: #ff6600;
  font-weight: 400;
  cursor: pointer;
}
.car-data > .data-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: .9rem;
}
.data-div > div {
  /*display: flex;*/
  width: 30%;
  margin: -5px;
}
.data-div > div > * {
  margin: 5px;
}
.data-div span::before {
  margin-right: 5px;
  font-weight: 500;
}
.data-div-info {
  display: grid;
  grid-template-columns: 1.5rem auto;
}
.hr {
  border-top: 1px dashed rgba(0,0,0,0.4);
  margin: 5px 0;
}
.car-prices > div:first-child, .car-prices > div:not(:first-child) > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.car-prices > div:not(:first-child) > div {
  margin-bottom: 5px;
}
.car-prices > div:not(:first-child) {
  display: flex;
  flex-direction: column;
}
.car-prices button {
  background-color: #ff6600;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0;
  margin-top: 5px;
}
.car-prices > div:first-child span:not(:first-child) {
  font-size: 2rem;
  font-weight: 500;
}
@media screen and (min-width: 992px){
  .car-prices {
    width: 20%;
  }
  .car-data {
    width: 50%;
  }
}
@media screen and (max-width: 991px){
  .car-info-card {
    flex-direction: column;
  }
  .car-info-card > *:not(:first-child) {
    margin: 10px 0;
  }
  img {
    margin: auto;
    min-width: 90%;
  }
  span {
    font-size: .8rem;
    font-weight: 400;
  }
  .data-div {
    flex-direction: column;
  }
  .data-div > div {
    width: 100%;
    margin: 0;
    flex-wrap: nowrap;
  }
  .data-div > div > * {
    margin: 5px 0;
  }
  .data-div-info {
    /*width: 100%;*/
  }
}
</style>