<template>
  <div class="car-filter">
    <div class="filters">
      <div class="button"><span class="icon-th"></span></div>
      <div class="button" @click="resetToDefault = !resetToDefault">Show All</div>
      <CarSelect name="" id="class" :default-option="'Class'" :options="classOptions" :set-to-default="resetToDefault" :index="4"
                 @on-click="onSelectChange($event, 'class')"></CarSelect>
      <CarSelect name="" id="manufacturer" :default-option="'Manufacturer'" :options="brandOptions" :set-to-default="resetToDefault" :index="3"
                 @on-click="onSelectChange($event, 'brand')"></CarSelect>
      <CarSelect name="" id="gearbox" :default-option="'Gearbox'" :options="gearboxOptions" :set-to-default="resetToDefault" :index="2"
                 @on-click="onSelectChange($event, 'transmission')"></CarSelect>
      <CarSelect name="" id="fuel" :default-option="'Fuel'" :options="fuelOptions" :set-to-default="resetToDefault" :index="1"
                 @on-click="onSelectChange($event, 'fuel')"></CarSelect>
      <CarSelect name="" id="max_pass" :default-option="'Max passengers'" :options="passengerOptions" :set-to-default="resetToDefault"
                 @on-click="onSelectChange($event, 'passengers')"></CarSelect>
    </div>
  </div>
</template>

<script>
import CarSelect from './CarSelect'

export default {
  name: "CarFilter",
  components: {
    CarSelect
  },
  data() {
    return {
      classOptions: ['Hatchback','Passenger Van','Sedan'],
      gearboxOptions: [{key: 1, value: 'Automatic'},{key: 2, value: 'Manual'}],
      fuelOptions: [{key: 1, value: 'Hybrid'},{key: 2, value: 'Unleaded 95'},{key: 3, value: 'Diesel'}],
      brandOptions: [],
      passengerOptions: [5],
      resetToDefault: false
    }
  },
  created() {
    this.$store.dispatch('isReady').then(() => {
      const brands = this.$store.getters.siteData.brands
      for (let brand of brands) {
        this.brandOptions.push({key: brand.id, value: brand.name, visible: true})
        // console.log(JSON.stringify(this.brandOptions))
      }
    })
  },
  methods: {
    onSelectChange(e, key) {
      e.key = key
      this.$emit('filter-change', e)
    }
  }
}
</script>

<style scoped>
  .car-filter {
    width: 72%;
    padding: 30px 14%;
    display: flex;
    flex-direction: column;
  }
  .filters {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /*height: 4rem;*/
    margin: -5px 0;
  }
  .filters > * {
    /*flex: 1;*/
    margin: 5px 0;
  }
  .filters > .button {
    height: 2.2rem;
    align-self: center;
    background-color: rgb(255, 102, 0);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    font-weight: 400;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.8);
    cursor: pointer;
    /*flex: 2;*/
  }
  .filters > .button:first-child {
    width: 80px;
    /*flex: 1;*/
  }
  .filters > .button:not(:first-child) {
    width: 110px;
  }
  .filters > .button > span {
    font-size: 1rem;
    align-self: center;
  }
</style>